const Select = ({label, data, select, click, type}) => {
    return(
        <div className="select">
            <div className={'s_title'}>{label}</div>
            <div className={'s_block' + (type === 'tip' ? ' -flex' : '')}>
                {type !== 'tip' && data.names.map((item, index) =>
                    <div className={select.includes(data.values[index]) ? 's_item active' : 's_item'} key={`item${index}`} onClick={() => click(data.values[index])} dangerouslySetInnerHTML={{ __html: item }} />
                )}
                {
                    type === 'tip' && data.names[0] && (<div className={'s_block__in'}>
                        {
                            data.names[0].map((item, index) =>
                                <div className={select.includes(data.values[0][index]) ? 's_item active' : 's_item'} key={`item${index}`} onClick={() => click(data.values[0][index])} dangerouslySetInnerHTML={{ __html: item }} />
                            )
                        }
                    </div>)
                }
                {
                    type === 'tip' && data.names[1] && (<div className={'s_block__in'}>
                        {
                            data.names[1].map((item, index) =>
                                <div className={select.includes(data.values[1][index]) ? 's_item active' : 's_item'} key={`item${index}`} onClick={() => click(data.values[1][index])} dangerouslySetInnerHTML={{ __html: item }} />
                            )
                        }
                    </div>)
                }
            </div>
        </div>
    )
}

export default Select
