import { useEffect } from "react";
import Layout from './Layout'

const App = () => {
    useEffect(() => {
        document.querySelector('.wrap').classList.add('active')
    }, [])

    return <Layout />
}

export default App;
