const flats = [
  {
    number: '1',
    square: 191,
    floor: '2',
    tip: '3',
    window: 'Окна на две стороны',
    view: '',
    features: [
      {
        name: 'Угловое остекление',
        value: 0,
      },
      {
        name: 'Сервисная кухня',
        value: 1,
      },
      {
        name: 'Балкон',
        value: 2,
      },
    ],
    price: 615020000,
    plan: 'https://brusov.moscow/fid/img/plan/BRUS_pln_GOR_ap1-A4-1.svg',
    genplan: 'https://brusov.moscow/fid/img/genplan/BRUS_pln_GOR_ap1-A4-2.svg',
    roza: 'https://brusov.moscow/fid/img/roza.svg',
    'floor-plan': 'https://brusov.moscow/fid/img/floor/Brusov_A3_Floor_2.svg',
    pano: [
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p3h2t1',
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p4h2t1',
    ],
  },
  {
    number: '2',
    square: 153,
    floor: '2',
    tip: '2',
    window: '',
    view: 'Вид на Брюсов пер.',
    features: [],
    price: 459000000,
    plan: 'https://brusov.moscow/fid/img/plan/BRUS_pln_GOR_ap2-A4-1.svg',
    genplan: 'https://brusov.moscow/fid/img/genplan/BRUS_pln_GOR_ap2-A4-2.svg',
    roza: 'https://brusov.moscow/fid/img/roza.svg',
    'floor-plan': 'https://brusov.moscow/fid/img/floor/Brusov_A3_Floor_2.svg',
    pano: [
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p5h2t1',
    ],
  },
  {
    number: '3',
    square: 106,
    floor: '2',
    tip: '1',
    window: '',
    view: 'Вид во двор',
    features: [],
    price: 360400000,
    plan: 'https://brusov.moscow/fid/img/plan/BRUS_pln_GOR_ap3-A4-1.svg',
    genplan: 'https://brusov.moscow/fid/img/genplan/BRUS_pln_GOR_ap3-A4-2.svg',
    roza: 'https://brusov.moscow/fid/img/roza.svg',
    'floor-plan': 'https://brusov.moscow/fid/img/floor/Brusov_A3_Floor_2.svg',
    pano: [
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p2h2t1',
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p6h3t1',
    ],
  },
  {
    number: '4',
    square: 207,
    floor: '2',
    tip: '3',
    window: 'Окна на две стороны',
    view: '',
    features: [
      {
        name: 'Угловое остекление',
        value: 0,
      },
      {
        name: 'Сервисная кухня',
        value: 1,
      },
      {
        name: 'Балкон',
        value: 2,
      },
    ],
    price: 608580000,
    plan: 'https://brusov.moscow/fid/img/plan/BRUS_pln_GOR_ap4-A4-1.svg',
    genplan: 'https://brusov.moscow/fid/img/genplan/BRUS_pln_GOR_ap4-A4-2.svg',
    roza: 'https://brusov.moscow/fid/img/roza.svg',
    'floor-plan': 'https://brusov.moscow/fid/img/floor/Brusov_A3_Floor_2.svg',
    pano: [
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p1h2t1',
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p6h2t1',
    ],
  },
  {
    number: '5',
    square: 225,
    floor: '3',
    tip: '3',
    window: 'Окна на две стороны',
    view: '',
    features: [
      {
        name: 'Угловое остекление',
        value: 0,
      },
      {
        name: 'Сервисная кухня',
        value: 1,
      },
      {
        name: 'Балкон',
        value: 2,
      },
    ],
    price: 758250000,
    plan: 'https://brusov.moscow/fid/img/plan/BRUS_pln_GOR_ap5-A4-1.svg',
    genplan: 'https://brusov.moscow/fid/img/genplan/BRUS_pln_GOR_ap5-A4-2.svg',
    roza: 'https://brusov.moscow/fid/img/roza.svg',
    'floor-plan': 'https://brusov.moscow/fid/img/floor/Brusov_A3_Floor_3.svg',
    pano: [
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p3h3t1',
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p4h3t1',
    ],
  },
  {
    number: '6',
    square: 247,
    floor: '3',
    tip: '4',
    window: '',
    view: 'Вид на Брюсов пер.',
    features: [],
    price: 802750000,
    plan: 'https://brusov.moscow/fid/img/plan/BRUS_pln_GOR_ap6-A4-1.svg',
    genplan: 'https://brusov.moscow/fid/img/genplan/BRUS_pln_GOR_ap6-A4-2.svg',
    roza: 'https://brusov.moscow/fid/img/roza.svg',
    'floor-plan': 'https://brusov.moscow/fid/img/floor/Brusov_A3_Floor_3.svg',
    pano: [
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p5h3t1',
    ],
  },
  {
    number: '7',
    square: 207,
    floor: '3',
    tip: '3',
    window: '',
    view: 'Вид во двор',
    features: [
      {
        name: 'Балкон',
        value: 2,
      },
    ],
    price: 639630000,
    plan: 'https://brusov.moscow/fid/img/plan/BRUS_pln_GOR_ap7-A4-1.svg',
    genplan: 'https://brusov.moscow/fid/img/genplan/BRUS_pln_GOR_ap7-A4-2.svg',
    roza: 'https://brusov.moscow/fid/img/roza.svg',
    'floor-plan': 'https://brusov.moscow/fid/img/floor/Brusov_A3_Floor_3.svg',
    pano: [
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p2h3t1',
    ],
  },
  {
    number: '8',
    square: 225,
    floor: '4',
    tip: '3',
    window: 'Окна на две стороны',
    view: '',
    features: [
      {
        name: 'Угловое остекление',
        value: 0,
      },
      {
        name: 'Сервисная кухня',
        value: 1,
      },
      {
        name: 'Балкон',
        value: 2,
      },
    ],
    price: 0,
    plan: 'https://brusov.moscow/fid/img/plan/.svg',
    genplan: 'https://brusov.moscow/fid/img/genplan/.svg',
    roza: 'https://brusov.moscow/fid/img/roza.svg',
    'floor-plan': 'https://brusov.moscow/fid/img/floor/Brusov_A3_Floor_4.svg',
    pano: [
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p1h3t1',
    ],
  },
  {
    number: '9',
    square: 247,
    floor: '4',
    tip: '4',
    window: 'Окна на две стороны',
    view: '',
    features: [
      {
        name: 'Угловое остекление',
        value: 0,
      },
      {
        name: 'Сервисная кухня',
        value: 1,
      },
      {
        name: 'Балкон',
        value: 2,
      },
    ],
    price: 839800000,
    plan: 'https://brusov.moscow/fid/img/plan/.svg',
    genplan: 'https://brusov.moscow/fid/img/genplan/.svg',
    roza: 'https://brusov.moscow/fid/img/roza.svg',
    'floor-plan': 'https://brusov.moscow/fid/img/floor/Brusov_A3_Floor_4.svg',
    pano: [
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p3h4t1',
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p4h4t1',
    ],
  },
  {
    number: '10',
    square: 207,
    floor: '4',
    tip: '3',
    window: 'Окна на две стороны',
    view: '',
    features: [
      {
        name: 'Сервисная кухня',
        value: 1,
      },
      {
        name: 'Балкон',
        value: 2,
      },
    ],
    price: 703800000,
    plan: 'https://brusov.moscow/fid/img/plan/BRUS_pln_GOR_ap10-A4-1.svg',
    genplan: 'https://brusov.moscow/fid/img/genplan/BRUS_pln_GOR_ap10-A4-2.svg',
    roza: 'https://brusov.moscow/fid/img/roza.svg',
    'floor-plan': 'https://brusov.moscow/fid/img/floor/Brusov_A3_Floor_4.svg',
    pano: [
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p2h4t1',
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p5h4t1',
    ],
  },
  {
    number: '11',
    square: 224,
    floor: '5',
    tip: '3',
    window: '',
    view: '',
    features: [],
    price: 940800000,
    plan: 'https://brusov.moscow/fid/img/plan/BRUS_pln_GOR_ap11-A4-1.svg',
    genplan: 'https://brusov.moscow/fid/img/genplan/BRUS_pln_GOR_ap11-A4-2.svg',
    roza: 'https://brusov.moscow/fid/img/roza.svg',
    'floor-plan': 'https://brusov.moscow/fid/img/floor/Brusov_A3_Floor_5.svg',
    pano: [],
  },
  {
    number: '12',
    square: 247,
    floor: '5',
    tip: '4',
    window: 'Окна на две стороны',
    view: '',
    features: [
      {
        name: 'Угловое остекление',
        value: 0,
      },
      {
        name: 'Сервисная кухня',
        value: 1,
      },
      {
        name: 'Балкон',
        value: 2,
      },
    ],
    price: 1012700000,
    plan: 'https://brusov.moscow/fid/img/plan/BRUS_pln_GOR_ap12-A4-1.svg',
    genplan: 'https://brusov.moscow/fid/img/genplan/BRUS_pln_GOR_ap12-A4-2.svg',
    roza: 'https://brusov.moscow/fid/img/roza.svg',
    'floor-plan': 'https://brusov.moscow/fid/img/floor/Brusov_A3_Floor_5.svg',
    pano: [
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p1h4t1',
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p6h4t1',
    ],
  },
  {
    number: '13',
    square: 207,
    floor: '5',
    tip: '3',
    window: 'Окна на две стороны',
    view: 'Вид на Кремль',
    features: [
      {
        name: 'Угловое остекление',
        value: 0,
      },
      {
        name: 'Сервисная кухня',
        value: 1,
      },
      {
        name: 'Балкон',
        value: 2,
      },
    ],
    price: 736920000,
    plan: 'https://brusov.moscow/fid/img/plan/BRUS_pln_GOR_ap13-A4-1.svg',
    genplan: 'https://brusov.moscow/fid/img/genplan/BRUS_pln_GOR_ap13-A4-2.svg',
    roza: 'https://brusov.moscow/fid/img/roza.svg',
    'floor-plan': 'https://brusov.moscow/fid/img/floor/Brusov_A3_Floor_5.svg',
    pano: [
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p3h5t1',
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p4h5t1',
    ],
  },
  {
    number: '14',
    square: 352,
    floor: '6',
    tip: '4',
    window: 'Окна на две стороны',
    view: 'Вид на Кремль',
    features: [
      {
        name: 'Сервисная кухня',
        value: 1,
      },
      {
        name: 'Балкон',
        value: 2,
      },
    ],
    price: 1598080000,
    plan: 'https://brusov.moscow/fid/img/plan/BRUS_pln_GOR_ap14-A4-1.svg',
    genplan: 'https://brusov.moscow/fid/img/genplan/BRUS_pln_GOR_ap14-A4-2.svg',
    roza: 'https://brusov.moscow/fid/img/roza.svg',
    'floor-plan': 'https://brusov.moscow/fid/img/floor/Brusov_A3_Floor_6.svg',
    pano: [
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p2h5t1',
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p5h5t1',
    ],
  },
  {
    number: '15',
    square: 327,
    floor: '6',
    tip: '4',
    window: 'Окна на две стороны',
    view: 'Вид на Кремль',
    features: [
      {
        name: 'Угловое остекление',
        value: 0,
      },
      {
        name: 'Сервисная кухня',
        value: 1,
      },
      {
        name: 'Балкон',
        value: 2,
      },
    ],
    price: 1347240000,
    plan: 'https://brusov.moscow/fid/img/plan/BRUS_pln_GOR_ap15-A4-1.svg',
    genplan: 'https://brusov.moscow/fid/img/genplan/BRUS_pln_GOR_ap15-A4-2.svg',
    roza: 'https://brusov.moscow/fid/img/roza.svg',
    'floor-plan': 'https://brusov.moscow/fid/img/floor/Brusov_A3_Floor_6.svg',
    pano: [
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p1h5t1',
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p6h5t1',
    ],
  },
  {
    number: '16',
    square: 352,
    floor: '7',
    tip: '4',
    window: '',
    view: 'Вид на Кремль',
    features: [],
    price: 0,
    plan: 'https://brusov.moscow/fid/img/plan/BRUS_pln_GOR_ap16-A4-1.svg',
    genplan: 'https://brusov.moscow/fid/img/genplan/BRUS_pln_GOR_ap16-A4-2.svg',
    roza: 'https://brusov.moscow/fid/img/roza.svg',
    'floor-plan': 'https://brusov.moscow/fid/img/floor/Brusov_A3_Floor_7.svg',
    pano: [],
  },
  {
    number: '17',
    square: 327,
    floor: '7',
    tip: '4',
    window: '',
    view: '',
    features: [],
    price: 1635000000,
    plan: 'https://brusov.moscow/fid/img/plan/BRUS_pln_GOR_ap17-A4-1.svg',
    genplan: 'https://brusov.moscow/fid/img/genplan/BRUS_pln_GOR_ap17-A4-2.svg',
    roza: 'https://brusov.moscow/fid/img/roza.svg',
    'floor-plan': 'https://brusov.moscow/fid/img/floor/Brusov_A3_Floor_7.svg',
    pano: [],
  },
  {
    number: '18',
    square: 540,
    floor: '8',
    tip: '0',
    window: '',
    view: '',
    features: [],
    price: 4050000000,
    plan: 'https://brusov.moscow/fid/img/plan/.svg',
    genplan: 'https://brusov.moscow/fid/img/genplan/.svg',
    roza: 'https://brusov.moscow/fid/img/roza.svg',
    'floor-plan': '',
    pano: [
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p2h8t1',
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p3h8t1',
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p4h8t1',
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p5h8t1',
      'https://tour.virtualland.ru/brusov/window-views/?startscene=s0p6h8t1',
    ],
  },
];

export default flats;
