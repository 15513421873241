const floors = {
    '2': {
        img: '/img/plans/svg/f_2.svg',
        size: [802, 559],
        flats: [
            {
                number: '1',
                path: 'M209 398.5V352H195.5V259.5H200.5L200.81 165.015H239V9H5L4 475.347C4 514.376 35.6983 546 74.8196 546H261.5V398.5H209Z'
            },
            {
                number: '2',
                path: 'M239 9V165H261V259H267V398.5H322V289H479.5V235.1H541V165H559V9H239Z'
            },
            {
                number: '3',
                path: 'M535 396.5V288.956V259H541V235H479.5V289H322V398.5H261.5V546H544V396.5H535Z'
            },
            {
                number: '4',
                path: 'M544 546V396.5H594V350.7H607V259H602V165H559V9H797.2V430.1V475.2C797.2 514 765.7 545.5 726.9 545.4L544 546Z'
            }
        ]
    },
    '3': {
        img: '/img/plans/svg/f_3.svg',
        size: [807, 558],
        flats: [
            {
                number: '5',
                path: 'M263.5 398.5H208.4V352.5H196.5V259H202V165H284.5V9H6.3V475.3C6.3 514.3 37.9 545.9 76.9 545.9H263.5V398.5Z'
            },
            {
                number: '6',
                path: 'M536.5 259H543V165H556V9H284.5V165H263V259H269V398.5H263.5V545.8H545V498.5H552.5V398H536.5V259Z'
            },
            {
                number: '7',
                path: 'M545 545.7V498.5H552.5V398H596V352H609V259.264H603.6V165H556V9H799.6V475.5C799.6 514.3 768.1 545.8 729.3 545.7H545Z'
            }
        ]
    },
    '4': {
        img: '/img/plans/svg/f_4.svg',
        size: [807, 558],
        flats: [
            {
                number: '8',
                path: 'M263.5 398.5H208.4V352.5H196.5V259H202V165H284.5V9H6.3V475.3C6.3 514.3 37.9 545.9 76.9 545.9H263.5V398.5Z'
            },
            {
                number: '9',
                path: 'M536.5 259H543V165H556V9H284.5V165H263V259H269V398.5H263.5V545.8H545V498.5H552.5V398H536.5V259Z'
            },
            {
                number: '10',
                path: 'M545 545.7V498.5H552.5V398H596V352H609V259.264H603.6V165H556V9H799.6V475.5C799.6 514.3 768.1 545.8 729.3 545.7H545Z'
            }
        ]
    },
    '5': {
        img: '/img/plans/svg/f_5.svg',
        size: [807, 558],
        flats: [
            {
                number: '11',
                path: 'M263.5 398.5H208.4V352.5H196.5V259H202V165H284.5V9H6.3V475.3C6.3 514.3 37.9 545.9 76.9 545.9H263.5V398.5Z'
            },
            {
                number: '12',
                path: 'M536.5 259H543V165H556V9H284.5V165H263V259H269V398.5H263.5V545.8H545V498.5H552.5V398H536.5V259Z'
            },
            {
                number: '13',
                path: 'M545 545.7V498.5H552.5V398H596V352H609V259.264H603.6V165H556V9H799.6V475.5C799.6 514.3 768.1 545.8 729.3 545.7H545Z'
            }
        ]
    },
    '6': {
        img: '/img/plans/svg/f_7.svg',
        size: [808, 558],
        flats: [
            {
                number: '14',
                path: 'M428 398H447L447 544.8H77.9C38.9 544.8 7.30005 513.2 7.30005 474.2V8H404V373H428V398ZM197.5 258V351H209V397.5H270.1V257.5H264.3V164H203V258H197.5Z'
            },
            {
                number: '15',
                path: 'M447 398V544.6H730.3C769.1 544.7 800.6 513.2 800.6 474.4V8H404V373H428V398H447ZM543.5 258V163.9H604.5L605 258H610V351.5H598.5V396.5H537.5V258H543.5Z'
            },
        ]
    },
    '7': {
        img: '/img/plans/svg/f_7.svg',
        size: [808, 558],
        flats: [
            {
                number: '16',
                path: 'M428 398H447L447 544.8H77.9C38.9 544.8 7.30005 513.2 7.30005 474.2V8H404V373H428V398ZM197.5 258V351H209V397.5H270.1V257.5H264.3V164H203V258H197.5Z'
            },
            {
                number: '17',
                path: 'M447 398V544.6H730.3C769.1 544.7 800.6 513.2 800.6 474.4V8H404V373H428V398H447ZM543.5 258V163.9H604.5L605 258H610V351.5H598.5V396.5H537.5V258H543.5Z'
            },
        ]
    },
    '8': {
        img: '/img/plans/svg/f_8.svg',
        size: [798.6, 545.4],
        flats: [
            {
                number: '18',
                path: 'M0 0.2 798.6 0 798.6 480.94 795.15 497.8 788.17 513.02 779.3 524.46 759.65 538.64 739.72 545.4 56.58 545.4 36.4 536.6 24.17 529.19 15.77 520.7 5.84 504.6 0.2 484.95 0 0.2Z'
            },
        ]
    },

}

export default floors
